 {
  box-sizing: border-box;
}

html,
body {
  background-color: black;
  height: 100vh;
}

.App {
  font-family: sans-serif;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.Screen {
  position: relative;
}

.Button {
  position: absolute;
}

.Button.debug {
  font-size: 10px;
  background-color: red;
  opacity: 0.9;
  color: white;
  text-decoration: none;
  padding: 2px;
  overflow: hidden;
}
